import { GetState } from "zustand";
import { flatMap } from "lodash";

import { CartProduct } from "Types";
import { OrderWindowsStore } from "Stores";
import { getRefIdAndQuantityHashMap } from "./productStockManagerUtils";

export class ProductStockManager {
    get: GetState<OrderWindowsStore>;

    constructor(get: GetState<OrderWindowsStore>) {
        this.get = get;
    }

    onUpdateRemainingStockQuantity = (cartProduct: CartProduct, shouldResetRemainingStock: boolean = false) => {
        const { getActiveOrderWindow, orderWindows, productsStock, setProductsStock } = this.get();
        const activeOrderWindow = getActiveOrderWindow();

        if (activeOrderWindow) {
            // When building a bundleCartProduct we don't have it in our orderWindows yet.
            // Therefor we need to concat it to our allCartProducts array.
            const allCartProducts = flatMap(orderWindows, orderWindow =>
                orderWindow.cartProducts.filter(existingCartProduct => cartProduct.id != existingCartProduct.id)
            ).concat(cartProduct);

            const refIdAndQuantityHashMap = getRefIdAndQuantityHashMap(allCartProducts);
            const updatedProductsStock = productsStock;
            for (let [id, productStock] of productsStock) {
                // If product has been removed from cart, we default to 1 for deletion
                let quantityToDeduct = refIdAndQuantityHashMap[id] || 1;

                // Remove deleted cartProducts tracked stock quantity
                if (shouldResetRemainingStock) {
                    const cartProductRefIdAndQuantity = getRefIdAndQuantityHashMap([cartProduct]);

                    if (id in cartProductRefIdAndQuantity) {
                        quantityToDeduct = Math.abs(cartProductRefIdAndQuantity[id] - quantityToDeduct);
                    }
                }

                const nextRemainingQuantity = Math.max(0, productStock.initialQuantity - quantityToDeduct);
                updatedProductsStock.set(id, {
                    remainingQuantity: nextRemainingQuantity,
                    initialQuantity: productStock.initialQuantity
                });
            }

            setProductsStock(updatedProductsStock);
        }
    };

    /**
     * [FUNCTION] Check if stock is available by product id
     * @param productId
     * @returns
     */
    isStockAvailable = (productId: string) => {
        const { productsStock } = this.get();
        const productIsManaged = productsStock.has(productId);
        if (productIsManaged) {
            const productStock = productsStock.get(productId);
            return (productStock?.remainingQuantity ?? 0) >= 1;
        }
        return true;
    };

    /**
     * [FUNCTION] Reduce stock quantity by product id (refProductId)
     * @param productId
     * @param quantity
     */
    reduceStockByQuantity = (productId: string, quantity: number = 1) => {
        const { productsStock, setProductsStock } = this.get();
        const productIsManaged = productsStock.has(productId);
        if (productIsManaged) {
            const updatedProductsStock = productsStock;
            const productStock = productsStock.get(productId);
            const currentStockQuantity = productStock?.remainingQuantity ?? 0;

            updatedProductsStock.set(productId, {
                remainingQuantity: currentStockQuantity - quantity,
                initialQuantity: productStock?.initialQuantity ?? 0
            });
            setProductsStock(updatedProductsStock);
        }
    };
}
