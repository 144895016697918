import { GetState } from "zustand";

import {
    Addon,
    CartProduct,
    DISCOUNT_TYPE,
    LastOrderProduct,
    LastOrderProductExtras,
    LastOrderProductReason,
    OnlineMenu,
    OnlineProduct,
    OnlineProductCategory,
    PosProviderRefProductHashMap,
    SelectedBundleProductItem,
    UserSubscriptions
} from "Types";
import { tempBundleProductOrderProduct } from "../admin/components/poses/shared/utils/cartController";
import {
    buildBundleProductCats,
    getBundleProductItems
} from "../customer/shared/online/components/OnlineProductModal/utils";
import { OrderWindowsStore } from "Stores";
import { AddonsHashMap, BundleItemOperation } from "Providers";
import {
    getLastOrderAddonsMissing,
    getLastOrderMenuProductModifications,
    getLastOrderOnlineDiscount,
    getLastOrderProductAddons,
    getLastOrderSelectedBundleModifications,
    isOutOfStock
} from "Utils";
import { AvailableBundleItem } from "../customer/shared/online/components/OnlineProductModal/components";
import { ExtendedBundleProductCategory } from "OnlineTypes";

type AddMenuProductFunction = (
    onlineMenu: OnlineMenu,
    lastOrderProduct: LastOrderProduct,
    shopId: string,
    isSubscriptionProduct: boolean,
    hasBeenAddedToCart: boolean,
    freeSubscriptionOnHold: boolean
) => void;

export class LastOrderManager {
    getOrderWindowStore: GetState<OrderWindowsStore>;

    private _addonHashMap?: AddonsHashMap;
    private _refProductHashMap?: PosProviderRefProductHashMap;
    private _notTrackedOutOfStockIds?: Set<string>;

    constructor(get: GetState<OrderWindowsStore>) {
        this.getOrderWindowStore = get;
    }

    /**
     * [FUNCTION] add issues with last order products + reason - in order to use in translations
     * @param lastOrderProduct
     * @param reason
     */
    addLastOrderIssue = (
        lastOrderProduct: LastOrderProduct,
        reason: LastOrderProductReason,
        otherIssues: string[] = []
    ) => {
        this.getOrderWindowStore().setLastOrderIssues({
            id: "",
            lastOrderProduct,
            lastOrderProductReason: reason,
            productId: "",
            addonOrModificationIssues: otherIssues
        });
    };

    /**
     * [FUNCTION] get menu product(s) from online menu
     * @param menu
     * @param refProductId
     * @returns
     */
    getMenuProductCategory = (menu: OnlineMenu, refProductId: string, findSubscriptionCategory: boolean) => {
        return menu.onlineProductCategories.filter(category => {
            return (
                (category?.isSubscriptionCategory ?? false) === findSubscriptionCategory &&
                category.onlineProducts.some(product => product.refProduct?.id === refProductId)
            );
        });
    };

    /**
     * [FUNCTION] get menu bundle product(s) from online menu
     * @param menu
     * @param refBundleProductId
     * @returns
     */
    getMenuBundleProductCategory = (
        menu: OnlineMenu,
        refBundleProductId: string,
        findSubscriptionCategory: boolean
    ) => {
        return menu.onlineProductCategories.filter(category => {
            return (
                (category?.isSubscriptionCategory ?? false) === findSubscriptionCategory &&
                category.onlineProducts.some(product => product.refBundleProduct?.id === refBundleProductId)
            );
        });
    };

    /**
     * [FUNCTION] get product from category by product id
     * @param productId
     * @param isMenuProduct
     * @param onlineCategory
     * @returns
     */
    getOnlineProductFromCategoryById = (
        productId: string,
        isMenuProduct: boolean,
        onlineCategory?: OnlineProductCategory | undefined
    ) => {
        return onlineCategory?.onlineProducts?.find(product =>
            isMenuProduct ? product.refProduct?.id === productId : product.refBundleProduct?.id === productId
        );
    };

    /**
     * [FUNCTION] Check + Get addons from last order and current addon hashmap
     * @param id
     * @param lastOrderAddons
     * @returns
     */
    getProductAddonsById = (id: string, lastOrderAddons: Addon[] | null | undefined) => {
        const hashMapAddons = !!this._addonHashMap ? this._addonHashMap[id] : null;
        return getLastOrderProductAddons(hashMapAddons, lastOrderAddons);
    };

    /**
     * [FUNCTION] Menu product extras - here will return modification and or addons for menu product
     * @param menuProduct
     * @param lastOrderProduct
     * @returns
     */
    getLastOrderMenuProductExtras = (
        menuProduct: OnlineProduct,
        lastOrderProduct: LastOrderProduct
    ): LastOrderProductExtras => {
        /** Get modifications on last order product - will return a true for matching or none! */
        const { hasMatchingModsOrNoMods, selectedModifications, missingModifications } =
            getLastOrderMenuProductModifications(menuProduct, lastOrderProduct?.modifications);

        /** Get addons on last order product */
        const selectedAddons = this.getProductAddonsById(lastOrderProduct.refProductId!, lastOrderProduct.addons);

        return {
            hasMatchingModsOrNoMods,
            selectedModifications,
            addons: selectedAddons,
            missingModifications
        };
    };

    /**
     * [FUNCTION] pass in addons to be used on a product / bundle selcted item
     *
     * * Will check if out of stock from the out of stock pos ids - different but the same to the tracking stock
     *
     * * Passes issues as well - if the addons are out of stock
     *
     * @param addons
     * @param lastOrderProduct
     * @param lastOrderReason
     * @returns
     */
    getInStockAddons = (
        addons: Addon[],
        lastOrderProduct: LastOrderProduct,
        lastOrderReason: LastOrderProductReason = LastOrderProductReason.OUT_OF_STOCK_ADDONS
    ) => {
        let addonNames = [] as string[];

        /** Check not tracked stock ids - this is where addons are added */
        const inStockAddons = addons?.reduce((addons: Addon[], addon: Addon) => {
            const outOfStockAddon = isOutOfStock(
                addon.name.toLowerCase(),
                this._notTrackedOutOfStockIds ?? new Set<string>()
            );
            if (!outOfStockAddon) {
                addons = [...addons, addon];
            } else {
                addonNames = [...addonNames, addon.name];
            }
            return addons;
        }, []);

        /** If out of stock add issue */
        if (addonNames.length) {
            this.addLastOrderIssue(lastOrderProduct, lastOrderReason, addonNames);
        }

        return inStockAddons;
    };

    /**
     * [FUNCTION] Single Bundle items to get extras - modifications and or addons
     * @param bundleItem
     * @param selectedBundleItem
     * @returns
     */
    getLastOrderBundleItemExtras = (
        bundleItem: AvailableBundleItem,
        selectedBundleItem: SelectedBundleProductItem
    ): LastOrderProductExtras => {
        /** Get modifications on selected bundle item - will reutrn a true for matching or none! */
        const { hasMatchingModsOrNoMods, selectedModifications, missingModifications } =
            getLastOrderSelectedBundleModifications(bundleItem, selectedBundleItem?.modifications);

        /** Get addons for selected bundle item - based off last order product */
        const selectedBundleItemAddons = this.getProductAddonsById(bundleItem?.id!, selectedBundleItem.addons);

        return {
            hasMatchingModsOrNoMods,
            selectedModifications,
            addons: selectedBundleItemAddons,
            missingModifications
        };
    };

    private addMenuProduct = (
        onlineMenu: OnlineMenu,
        lastOrderProduct: LastOrderProduct,
        shopId: string,
        isSubscriptionProduct: boolean,
        hasBeenAddedToCart: boolean,
        freeSubscriptionOnHold: boolean
    ) => {
        const productId = lastOrderProduct.refProductId!;

        /** Get product category by product id + if subscription product (NOTE: we get the category by product id as product could have moved category) */
        let menuCategory = this.getMenuProductCategory(onlineMenu, productId, isSubscriptionProduct);

        const isInCartOrOnHold = hasBeenAddedToCart || freeSubscriptionOnHold;

        if (menuCategory.length && isSubscriptionProduct && isInCartOrOnHold) {
            /** REASONS
             * 1. If this is a subscription category + product - category will appear
             * 2. However if that subscription has already been added we don't want it added again
             * 3. But when pressing last order it will add as a normal product
             */
            menuCategory = [];
        }

        /** It could be a subscription product but not in the normal menu subscription category */
        if (isSubscriptionProduct && !menuCategory?.length) {
            menuCategory = this.getMenuProductCategory(onlineMenu, productId, false);
        }

        if (!menuCategory.length && !!onlineMenu.onlineUpsellCategory) {
            /** There is a possible outcome here that an upsell could only be in upsell and not the other categories
             */
            const hasUpsellProduct = onlineMenu.onlineUpsellCategory.onlineProducts.some(
                product => product.refProduct?.id === productId
            );
            if (hasUpsellProduct) {
                menuCategory = [onlineMenu.onlineUpsellCategory];
            }
        }

        if (menuCategory.length) {
            /** Get product from category */
            const menuProduct = this.getOnlineProductFromCategoryById(productId, true, menuCategory[0]);

            if (!!menuProduct && menuProduct.isOutOfStock) {
                /** If out of stock add to last order issue array */
                this.addLastOrderIssue(lastOrderProduct, LastOrderProductReason.OUT_OF_STOCK);
            }

            if (!!menuProduct && !menuProduct.isOutOfStock) {
                /** Check the extra on menu product (mods + addons) */
                const { hasMatchingModsOrNoMods, selectedModifications, addons, missingModifications } =
                    this.getLastOrderMenuProductExtras(menuProduct, lastOrderProduct);

                if (hasMatchingModsOrNoMods) {
                    /** Get menu product online discount (if present) */
                    const onlineFixedDiscount = getLastOrderOnlineDiscount(
                        onlineMenu.id,
                        menuCategory[0].id,
                        menuProduct,
                        this.getOrderWindowStore().allActiveFixedDiscounts
                    );

                    const sameNumberOfAddons = (addons?.length ?? 0) === (lastOrderProduct?.addons?.length ?? 0);

                    if (!sameNumberOfAddons) {
                        /** Here we want to still add product even if the addons are not the same
                         * This will add a last order issue inorder to notify the user
                         */
                        const addonIssues = getLastOrderAddonsMissing(addons ?? [], lastOrderProduct?.addons ?? []);
                        this.addLastOrderIssue(
                            lastOrderProduct,
                            LastOrderProductReason.INCLUDED_MISSING_ADDONS,
                            addonIssues
                        );
                    }

                    const selectedAddons = this.getInStockAddons(addons ?? [], lastOrderProduct);

                    /** Add menu product (convert to cart product) to order window */
                    this.getOrderWindowStore().addOrderProductToCart(
                        menuProduct?.menuProduct!,
                        shopId,
                        menuCategory[0].id!,
                        selectedModifications,
                        false,
                        !!lastOrderProduct.comment ? lastOrderProduct.comment : null,
                        selectedAddons ?? [],
                        false,
                        !!onlineFixedDiscount ? onlineFixedDiscount : undefined,
                        menuProduct.isStockTracked,
                        undefined,
                        true
                    );
                } else {
                    this.addLastOrderIssue(
                        lastOrderProduct,
                        LastOrderProductReason.MISSING_MODIFICATIONS,
                        missingModifications
                    );
                }
            } else {
                this.addLastOrderIssue(
                    lastOrderProduct,
                    menuProduct?.isOutOfStock
                        ? LastOrderProductReason.OUT_OF_STOCK
                        : LastOrderProductReason.MISSING_PRODUCT
                );
            }
        } else {
            this.addLastOrderIssue(lastOrderProduct, LastOrderProductReason.MISSING_PRODUCT);
        }
    };

    private addMenuBundleProduct = (
        onlineMenu: OnlineMenu,
        lastOrderProduct: LastOrderProduct,
        shopId: string,
        isSubscriptionProduct: boolean,
        hasBeenAddedToCart: boolean,
        freeSubscriptionOnHold: boolean
    ) => {
        const productId = lastOrderProduct.refBundleProductId!;

        /** Get Bundle Category by ref bundle product id
         * (NOTE: we get the category by product id as product could have moved category)
         * Also we get subscription category
         *  */
        let bundleCategory = this.getMenuBundleProductCategory(onlineMenu, productId, isSubscriptionProduct);

        /** if already in cart or if free subscription is on hold */
        const isInCartOrOnHold = hasBeenAddedToCart || freeSubscriptionOnHold;

        if (bundleCategory.length && isSubscriptionProduct && isInCartOrOnHold) {
            /** REASONS
             * 1. If this is a subscription category + product - category will appear
             * 2. However if that subscription has already been added and is Free we don't want it added again
             * 3. But when pressing last order it will add as a normal product
             */
            bundleCategory = [];
        }

        /** It could be a subscription product but not in the normal menu subscription category */
        if (isSubscriptionProduct && !bundleCategory?.length) {
            bundleCategory = this.getMenuBundleProductCategory(onlineMenu, productId, false);
        }

        if (!bundleCategory.length && !!onlineMenu.onlineUpsellCategory) {
            /** There is a possible outcome here that an upsell could only be in upsell and not the other categories
             */
            const hasUpsellProduct = onlineMenu.onlineUpsellCategory.onlineProducts.some(
                product => product.refBundleProduct?.id === productId
            );
            if (hasUpsellProduct) {
                bundleCategory = [onlineMenu.onlineUpsellCategory];
            }
        }

        if (bundleCategory.length) {
            /** Get bundle product from category */
            const menuBundleProduct = this.getOnlineProductFromCategoryById(productId, false, bundleCategory[0]);

            if (!!menuBundleProduct && menuBundleProduct.isOutOfStock) {
                this.addLastOrderIssue(lastOrderProduct, LastOrderProductReason.OUT_OF_STOCK);
            }

            if (!!menuBundleProduct && !menuBundleProduct.isOutOfStock) {
                /** Create temp cart product from bundle */
                const cartProduct = tempBundleProductOrderProduct(
                    menuBundleProduct.menuBundleProduct!,
                    shopId,
                    bundleCategory[0].id,
                    1
                ) as CartProduct;

                /** Construct all bundle product categories from current bundle product + refproduct hashmap */
                const bundleCategories = menuBundleProduct?.refBundleProduct?.bundleProductCategories ?? [];
                const altCategories = [...buildBundleProductCats(bundleCategories, this._refProductHashMap!)].sort(
                    (a, b) => a.sortOrder - b.sortOrder
                );

                /** Check addons on overall bundle product */
                const selectedAddons = this.getProductAddonsById(
                    lastOrderProduct.refBundleProductId!,
                    lastOrderProduct.addons
                );

                const sameNumberOfAddons = (selectedAddons?.length ?? 0) === (lastOrderProduct?.addons?.length ?? 0);

                if (!sameNumberOfAddons) {
                    /** Here we want to still add product even if the addons are not the same
                     * This will add a last order issue in order to notify the user
                     */

                    const addonIssues = getLastOrderAddonsMissing(selectedAddons ?? [], lastOrderProduct?.addons ?? []);
                    this.addLastOrderIssue(
                        lastOrderProduct,
                        LastOrderProductReason.INCLUDED_MISSING_ADDONS,
                        addonIssues
                    );
                }

                /** Check stock for addons */
                const inStockBundleAddons = this.getInStockAddons(
                    selectedAddons,
                    lastOrderProduct,
                    LastOrderProductReason.OUT_OF_STOCK_ADDONS_BUNDLE
                );

                /** create temp cart product */
                let tempCartProduct = {
                    ...cartProduct,
                    orderProduct: {
                        ...cartProduct.orderProduct,
                        isStockTracked: menuBundleProduct.isStockTracked,
                        isLastOrderProduct: true
                    }
                } as CartProduct;

                /** Options to keep track of when going though bundle items */
                let lastOrderIssues: LastOrderProductReason[] = [];
                let stockTrackingIds: string[] = [];
                let bundleItemNames: string[] = [];

                const newBundleCartProduct = lastOrderProduct?.selectedBundleProductItems?.reduce(
                    (updatedCartProduct: CartProduct, item: SelectedBundleProductItem, _: number) => {
                        let cartProductToUpdate = updatedCartProduct;
                        /** Find selected bundle product item */
                        const menuCategory = altCategories?.find(
                            (cat: ExtendedBundleProductCategory) => cat.id === item.bundleProductCategoryId
                        );
                        if (!!menuCategory) {
                            /** Get Bundle Product items  */
                            const bundleProductItems = getBundleProductItems(
                                menuCategory.refProducts,
                                updatedCartProduct,
                                menuCategory.id,
                                new Map()
                            );

                            const _item = bundleProductItems.find(value => value.id === item.refProductId);

                            if (!!_item) {
                                /** If stock is tracked then add selected item ids */
                                if (menuBundleProduct.isStockTracked) {
                                    stockTrackingIds = [...(stockTrackingIds ?? []), _item?.id];
                                }

                                /** Get modifications + addons on selected bundle item */
                                const { hasMatchingModsOrNoMods, selectedModifications, addons, missingModifications } =
                                    this.getLastOrderBundleItemExtras(_item!, item);

                                if (!hasMatchingModsOrNoMods) {
                                    /** Add to issues - this will prevent the product being added to cart */
                                    const missingModText = !!missingModifications.length
                                        ? `- ${missingModifications.join(", ")}`
                                        : "";
                                    bundleItemNames = [...bundleItemNames, `${item.name} ${missingModText}`];
                                    lastOrderIssues = [
                                        ...lastOrderIssues,
                                        LastOrderProductReason.MISSING_BUNDLE_ITEM_MODIFICATIONS
                                    ];
                                }

                                /** Update and constuct bundle item */
                                cartProductToUpdate =
                                    this.getOrderWindowStore().CartManager.updateBundleCartProductWithSelectedBundleItems(
                                        cartProductToUpdate,
                                        _item as any,
                                        menuCategory,
                                        hasMatchingModsOrNoMods ? (selectedModifications as any) : null,
                                        true,
                                        null,
                                        BundleItemOperation.ADD
                                    ) as CartProduct;

                                const bundleItemHasSameAddons = (addons?.length ?? 0) === (item?.addons?.length ?? 0);

                                if (!bundleItemHasSameAddons) {
                                    /** Here we want to still add bundle item even if the addons are not the same
                                     * This will add a last order issue inorder to notify the user
                                     */

                                    const addonIssues = getLastOrderAddonsMissing(item?.addons ?? [], addons ?? []);

                                    this.addLastOrderIssue(
                                        lastOrderProduct,
                                        LastOrderProductReason.INCLUDED_BUNDLE_ITEM_MISSING_ADDONS,
                                        addonIssues
                                    );
                                }

                                /** Check stock for addons */
                                const inStockAddons = this.getInStockAddons(
                                    addons ?? [],
                                    lastOrderProduct,
                                    LastOrderProductReason.OUT_OF_STOCK_ADDONS_SELECTED_BUNDLE_ITEM
                                );

                                if (!!inStockAddons) {
                                    /** If addons on selected bundle item - add to ongoing cart product */
                                    const selectBundleItems =
                                        cartProductToUpdate.orderProduct.selectedBundleProductItems?.map(
                                            (item: SelectedBundleProductItem) =>
                                                item.refProductId === _item?.id
                                                    ? {
                                                          ...item,
                                                          addons: inStockAddons
                                                      }
                                                    : item
                                        );

                                    cartProductToUpdate = {
                                        ...cartProductToUpdate,
                                        orderProduct: {
                                            ...cartProductToUpdate.orderProduct,
                                            selectedBundleProductItems: selectBundleItems
                                        }
                                    };
                                }
                            } else {
                                /** Item is missing */
                                bundleItemNames = [...bundleItemNames, item.name];
                                lastOrderIssues = [...lastOrderIssues, LastOrderProductReason.MISSING_BUNDLE_ITEM];
                            }
                        } else {
                            //if menuCategory is undefined, then don't allow to add item to the cart
                            lastOrderIssues = [...lastOrderIssues, LastOrderProductReason.MISSING_BUNDLE_ITEM];
                        }

                        return { ...cartProductToUpdate };
                    },
                    tempCartProduct
                );

                /** Add a double check here to make sure that selected bundle item has a ref product id */
                const bundleItemsHaveRefIds = newBundleCartProduct?.orderProduct?.selectedBundleProductItems?.every(
                    prod => !!prod?.refProductId
                );

                if (!bundleItemsHaveRefIds) {
                    /** If there is a missing id - this can cause issues with the cart (1 time this has happened)
                     * But if it happens again it will hit this and prevent the bundle added to the cart!
                     */
                    const missingItemNames =
                        newBundleCartProduct?.orderProduct?.selectedBundleProductItems
                            ?.filter(item => !item?.refProductId)
                            ?.map(item => item.name) || [];
                    
                    /** Add item names for the modal - to tell the customer which is missing */
                    bundleItemNames = [...bundleItemNames, ...missingItemNames];
                    /** Add to issues with reason for translation */
                    lastOrderIssues = [...lastOrderIssues, LastOrderProductReason.MISSING_BUNDLE_ITEM];
                }

                if (!lastOrderIssues.length) {
                    const onlineFixedDiscount = getLastOrderOnlineDiscount(
                        onlineMenu.id,
                        bundleCategory[0].id!,
                        menuBundleProduct!,
                        this.getOrderWindowStore().allActiveFixedDiscounts
                    );

                    /** Add bundle Cart product */
                    this.getOrderWindowStore().upsertBundleCartProductToCart(
                        newBundleCartProduct!,
                        1,
                        !!onlineFixedDiscount ? onlineFixedDiscount : undefined,
                        !!lastOrderProduct.comment ? lastOrderProduct.comment : "",
                        inStockBundleAddons,
                        false
                    );
                } else {
                    lastOrderIssues.forEach(issue => {
                        this.addLastOrderIssue(lastOrderProduct, issue, bundleItemNames);
                    });
                }
            } else {
                this.addLastOrderIssue(
                    lastOrderProduct,
                    menuBundleProduct?.isOutOfStock
                        ? LastOrderProductReason.OUT_OF_STOCK
                        : LastOrderProductReason.MISSING_PRODUCT
                );
            }
        } else {
            this.addLastOrderIssue(lastOrderProduct, LastOrderProductReason.MISSING_PRODUCT);
        }
    };

    /**
     * [FUNCTION] Quick check stock tracking
     * @param lastOrderProduct
     * @returns
     */
    private isStockAvailable = (lastOrderProduct: LastOrderProduct) => {
        const isMenuProduct = !!lastOrderProduct.refProductId;
        const productId = lastOrderProduct.refProductId || lastOrderProduct.refBundleProductId!;

        /** If menu product then just a simple check */
        if (isMenuProduct) {
            return this.getOrderWindowStore().ProductStockManager.isStockAvailable(productId);
        }

        /** If bundle product then collect the product ids from the bundle items */
        const productIds = lastOrderProduct.selectedBundleProductItems?.map(item => item.refProductId);

        /** Bundle Items - make sure each of the products are in stock */
        return productIds?.every(id => this.getOrderWindowStore().ProductStockManager.isStockAvailable(id));
    };

    /**
     * [FUNCTION] Depending on the quantity / type of menu product - will fire the function added
     * @param addProductFunction
     * @returns
     */
    private addProductByQuantity =
        (addProductFunction: AddMenuProductFunction) =>
        (
            onlineMenu: OnlineMenu,
            lastOrderProduct: LastOrderProduct,
            shopId: string,
            isSubscriptionProduct: boolean,
            freeSubscriptionOnHold: boolean,
            productSubscriptionIds?: string[]
        ) => {
            const productIsSubscription =
                productSubscriptionIds?.includes(
                    lastOrderProduct?.refProductId || lastOrderProduct?.refBundleProductId!
                ) ?? false;

            Array.from({ length: lastOrderProduct.quantity }).forEach(() => {
                const isStockAvailable = this.isStockAvailable(lastOrderProduct);
                /** This is a check to see if the product is in the cart */
                const hasBeenAddedToCart = this.isProductOrSimilarSubscriptionInCart(lastOrderProduct);
                if (isStockAvailable) {
                    const productSubscriptionIsAvailable =
                        isSubscriptionProduct || (productIsSubscription && !hasBeenAddedToCart);
                    addProductFunction(
                        onlineMenu,
                        lastOrderProduct,
                        shopId,
                        productSubscriptionIsAvailable,
                        hasBeenAddedToCart,
                        freeSubscriptionOnHold
                    );
                } else {
                    this.addLastOrderIssue(
                        lastOrderProduct,
                        !!lastOrderProduct.refProductId
                            ? LastOrderProductReason.OUT_OF_STOCK
                            : LastOrderProductReason.BUNDLE_ITEM_OUT_OF_STOCK
                    );
                }
            });
        };

    /**
     * [FUNCTION] helper for cart list - checks if last order product is already in the cart
     * -- Why? this is going to be used for a subscription check so that if clicked again will
     * enter a product that is not discounted
     * @param lastOrderProduct
     * @returns
     */
    private isProductOrSimilarSubscriptionInCart = (lastOrderProduct: LastOrderProduct) => {
        /** Get order window */
        const orderWindow = this.getOrderWindowStore().getActiveOrderWindow();

        /** Check if there are cart products */
        const hasCartProducts = !!orderWindow?.cartProducts.length;

        if (!hasCartProducts) {
            return false;
        }

        const productId = lastOrderProduct?.refProductId || lastOrderProduct.refBundleProductId!;

        /** Check if a free discount for subsription is already present */
        const subscriptionCartProduct = orderWindow.cartProducts.find(
            cart =>
                cart.fixedDiscount?.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT &&
                cart.fixedDiscount?.subscriptionProductMeta?.percentageDiscount === 100
        );

        /** If it is, then it is already in the cart */
        if (!!subscriptionCartProduct) {
            return true;
        }

        /** Check if last order product is already in the cart */
        return (
            orderWindow.cartProducts.some(
                cart =>
                    cart.menuProduct?.refProduct?.id === productId ||
                    cart.menuBundleProduct?.refBundleProduct?.id === productId
            ) ?? false
        );
    };

    /**
     * [FUNCTION] Last Order Manager main function
     * Here will loop last order products and establish type of product from the menus
     * and if it is a subscription product
     * @param orderId
     * @param shopId
     * @param userSubscriptions
     * @param addons
     * @param refProducts
     */
    createCartProductsFromLastOrder = (
        orderId: string,
        shopId: string,
        freeSubscriptionIsOnHold: boolean,
        userSubscriptions?: UserSubscriptions,
        addons?: AddonsHashMap,
        refProducts?: PosProviderRefProductHashMap,
        notTrackedOutOfStockIds?: Set<string>
    ) => {
        /** Set on class hashmaps to be used by other functions */
        this._addonHashMap = addons;
        this._refProductHashMap = refProducts;
        this._notTrackedOutOfStockIds = notTrackedOutOfStockIds;

        /** Get active menus + last order products */
        const { activeMenus, lastOrders } = this.getOrderWindowStore();

        /** Get Order from lastOrders */
        const lastOrder = lastOrders.find(order => order.orderId === orderId);
        const lastOrderProducts = lastOrder?.lastOrderProducts;

        /** If user has subscription */
        const userHasSubscription = !!userSubscriptions?.length;

        /** Get Users subscriptions */
        const userSubscriptionProducts = userSubscriptions?.flatMap(sub => sub.subscription.subscriptionProducts);

        /** Get all subscription products that are freee */
        const productIdsForFreeSubscription = userSubscriptionProducts
            ?.filter(sub => sub.percentageDiscount === 100)
            .map(sub => sub.refProductId);

        /** Check if subscription (free) is on hold */
        const isFreeSubscriptionOnHold = !!productIdsForFreeSubscription?.length && freeSubscriptionIsOnHold;

        /** check active products from last order - order by subscription product */
        const activeLastOrderProducts = (lastOrderProducts || []).sort(
            (a, b) => Number(b.subscriptionProduct) - Number(a.subscriptionProduct)
        );

        /** Loop active last order products */
        activeLastOrderProducts?.forEach((lastOrderProduct: LastOrderProduct) => {
            /** Check type of product - determines the function used */
            const isMenuProduct = !!lastOrderProduct.refProductId;

            if (!!lastOrderProduct.menuIds?.length) {
                /** Start - get first available menu */
                const selectedMenu = activeMenus.find(menu => lastOrderProduct.menuIds?.includes(menu.id));

                if (!!selectedMenu) {
                    /** Check if subscription product */
                    const isASubscriptionProduct = lastOrderProduct.subscriptionProduct && userHasSubscription;

                    /** Set which function to use depending on ref id - with quantity */
                    const addProduct = this.addProductByQuantity(
                        isMenuProduct ? this.addMenuProduct : this.addMenuBundleProduct
                    );

                    /** Add product to cart list */
                    addProduct(
                        selectedMenu!,
                        lastOrderProduct,
                        shopId,
                        isASubscriptionProduct,
                        isFreeSubscriptionOnHold,
                        productIdsForFreeSubscription
                    );
                } else {
                    /** Technically this should not happen - but things are never 100% */
                    this.addLastOrderIssue(lastOrderProduct, LastOrderProductReason.MENU_NOT_ACTIVE);
                }
            } else {
                /** Technically this should not happen - but things are never 100% */
                this.addLastOrderIssue(lastOrderProduct, LastOrderProductReason.NOT_IN_MENU);
            }
        });
    };
}
